.image-backgroud-div {
  position: relative;
}

.image-backgroud-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 65%;
}

.image-backgroud-img-2 {
  position: absolute;
  top: -4rem;
  right: 0;
  width: 73%;
}

.DoitXrImage {
  width: 100%;
}

.play {
  position: absolute;
  bottom: 5em;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: #04e0cd 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border: 3px solid #4dfff0;
  border-radius: 78px;
  opacity: 1;
}

.play-icon {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  font-size: 5em;
}

@media (min-width: 1024px) {
  .mockupsText-mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .mockupsText-desktop {
    display: none;
  }
}

@media (max-width: 800px) {
  .play {
    width: 60px;
    height: 60px;
  }
  .play-icon {
    font-size: 2.5em;
  }
}

@media (max-width: 800px) {
  .play {
    width: 60px;
    height: 60px;
  }
  .play-icon {
    font-size: 2.5em;
  }
}

@media (max-width: 1000px) {
  .text {
    text-align: center;
  }
}

@media (min-width: 1000px) {
  .text {
    text-align: left;
  }
}

@media (max-width: 1200px) {
  .text-mediaXL {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-mediaXL {
    text-align: left;
  }
}

@media (max-width: 800px) {
  .hidden-mobile {
    display: none;
  }
  .play {
    left: 50%;
  }
}

@media (min-width: 800px) {
  .hidden-desktop {
    display: none;
  }
}

.center {
  position: absolute;
  top: 9em;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 0px;
}

.plataform {
  text-align: center;
  font-size: 1em;
  letter-spacing: 3px;
  font-weight: bold;
  color: #3e70fc;
  text-transform: uppercase;
  opacity: 1;
  line-height: normal;
}

.aprendizaje {
  text-align: left;
  font-size: 2.7em;
  font-weight: bold;
  letter-spacing: -1px;
  color: #16171b;
  line-height: normal;
}

.inmersivo {
  text-align: left;
  font-size: 4.3em;
  font-weight: bold;
  letter-spacing: 2px;
  color: #16171b;
  line-height: 0.7em;
}

.webVr {
  text-align: left;
  font-size: 1.5em;
  letter-spacing: 0px;
  font-weight: bold;
  color: #16171b;
  opacity: 1;
  line-height: normal;
}

.btnSeccion-1 {
  width: 100%;
  background: #f7f9ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #6cded47e;
  border: 2px solid #04e0cd;
  border-radius: 52px;
  opacity: 1;
}

.btnText1 {
  font-size: 1.2em;
  text-align: left;
  letter-spacing: 0px;
  color: #16171b;
}

.btnText2 {
  font-size: 1.2em;
  text-align: left;
  letter-spacing: 0px;
  color: #04e0cd;
}

.cardWhite {
  padding: 1em;
  background: transparent linear-gradient(180deg, #ffffff 0%, #ffffffdb 100%) 0%
    0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
}

.text-1 {
  text-align: left;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0px;
}

.text-2 {
  text-align: left;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0px;
  color: #16171b;
}

.colorAumenta {
  color: #3e70fc;
}

.colorMejora {
  color: #04e0cd;
}

.colorReduce {
  color: #fd5085;
}

#seccion-3 {
  background: #000000 0% 0% no-repeat padding-box;
  color: white;
}

.title-s3 {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-s3 {
  text-align: center;
  letter-spacing: 0px;
  color: #f7f9ff;
  opacity: 1;
}

.text-link-s3 {
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-decoration: none;
}

.text-button-s3 {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #04e0cd;
  border-radius: 52px;
  opacity: 1;
  font-weight: bold;
  color: #04e0cd;
  opacity: 1;
  text-decoration: none;
}

.seccion-text-1 {
  font-size: 1.8em;
  font-weight: bold;
  letter-spacing: 0px;
  color: #16171b;
  opacity: 1;
}

.seccion-text-2 {
  font-size: 2.7em;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3e70fc;
  opacity: 1;
}

.seccion-text-3 {
  font-weight: bold;
  letter-spacing: 0px;
  color: #3e70fc;
}

.seccion-text-4 {
  font-weight: normal;
  letter-spacing: 0px;
  color: #16171b;
}

.seccion-text-5 {
  font-weight: bold;
  letter-spacing: 0px;
  color: #16171b;
}

.Oculus {
  position: absolute;
  top: 12em;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seccion-4-text-1 {
  letter-spacing: 0px;
  color: #3e70fc;
}

.seccion-4-text-2 {
  letter-spacing: 0px;
  color: #16171b;
}

.seccion-4-text-3 {
  letter-spacing: 0px;
  color: #3e70fc;
  opacity: 1;
  line-height: 3em;
}

.seccion-4-text-4 {
  letter-spacing: 0px;
  color: #16171b;
  opacity: 1;
  line-height: 3em;
}

@media (max-width: 800px) {
  .touch-seccion-4-1 {
    position: absolute;
    top: 0em;
    right: 4em;
    line-height: 0px;
  }

  .touch-seccion-4-2 {
    position: absolute;
    bottom: 5em;
    right: 15em;
  }

  .touch-seccion-4-3 {
    position: absolute;
    bottom: 3em;
    right: 3em;
  }
  .touch-seccion-4-1-c {
    position: absolute;
    top: -9em;
    right: -1em;
  }

  .touch-seccion-4-2-c {
    position: absolute;
    top: -2em;
    left: -1em;
  }

  .touch-seccion-4-3-c {
    position: absolute;
    bottom: -5em;
    right: 6em;
  }
  .card-seccion-4 {
    width: 200px;
  }
}

@media (min-width: 800px) {
  .touch-seccion-4-1 {
    position: absolute;
    top: 4em;
    right: 12em;
    line-height: 0px;
  }

  .touch-seccion-4-2 {
    position: absolute;
    bottom: 12em;
    right: 18em;
  }

  .touch-seccion-4-3 {
    position: absolute;
    bottom: 14em;
    right: 36em;
  }
  .touch-seccion-4-1-c {
    position: absolute;
    top: -3em;
    right: 6em;
  }

  .touch-seccion-4-2-c {
    position: absolute;
    bottom: 5em;
    right: 8em;
  }

  .touch-seccion-4-3-c {
    position: absolute;
    bottom: 15em;
    right: 53em;
  }
  .card-seccion-4 {
    width: 220px;
  }
}
.card-seccion-4-mini {
  width: 180px;
  height: 100px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 1em;
}

.card-seccion-4 {
  height: 130px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 1em;
}

#seccion-5 {
  background: #3e70fc 0% 0% no-repeat padding-box;
  opacity: 1;
}

#seccion-6 {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
}

.seccion-5-text-1 {
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 1.2em;
  color: #ffffff;
  opacity: 1;
}

.seccion-5-text-2 {
  font-weight: bold;
  line-height: 1.8em;
  letter-spacing: 0px;
  color: #3e70fc;
  opacity: 1;
}

.seccion-5-text-3 {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.seccion-7-text-1 {
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
}

.color-green {
  color: #04e0cd;
}

.seccion-7-text-1-02 {
  letter-spacing: 0px;
  opacity: 1;
}

.seccion-7-text-2 {
  letter-spacing: 3px;
  text-transform: uppercase;
  opacity: 1;
}

.seccion-7-text-3 {
  letter-spacing: 0px;
  color: #16171b;
  opacity: 1;
}

@media (max-width: 1200px) {
  .seccion-7-mobile {
    display: none;
  }
}

@media (min-width: 1200px) {
  .seccion-7-desktop {
    display: none;
  }
}
@media (min-width: 500px) {
  .pt-seccion7 {
    padding-top: 5rem;
  }
}
.seccion-7-text-mobile {
  margin-top: 4;
}

.content-1 {
  position: absolute;
  width: 20%;
  top: 2%;
  left: 5%;
}
.content-2 {
  position: absolute;
  width: 20%;
  top: 8%;
  right: 2%;
}
.content-3 {
  position: absolute;
  width: 20%;
  right: 50%;
  left: 50%;
}

.seccion-8-text-1 {
  letter-spacing: 3px;
  color: #16171b;
  text-transform: uppercase;
  opacity: 1;
}

#seccion-9 {
  background: #3e70fc 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 17px;
  opacity: 1;
}

.background-white {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  height: 100%;
}

.seccion-9-text-1 {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.seccion-9-text-2 {
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.seccion-9-text-3 {
  letter-spacing: 0px;
  opacity: 1;
}

.z-index-video {
  z-index: -1;
}
.videoContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.videoContainer video {
  min-width: 100%;
  min-height: 100%;

  position: relative;
  z-index: -2;
}
.videoContainer .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  top: 0;
  left: 0px;
  z-index: -1;
  background: black;
  opacity: 0.3;
}

/*
  BUTTON EXPAND
*/

.btn-expand {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 3rem;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
  cursor: pointer;
}
.icon-expand {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
.text-expand {
  white-space: nowrap;
  padding-right: 1rem;
}
.btn-expand:hover {
  max-width: 300px;
}

.animate__delay-8s {
  -webkit-animation-delay: calc(1s * 8);
  animation-delay: calc(1s * 8);
  -webkit-animation-delay: calc(var(--animate-delay) * 8);
  animation-delay: calc(var(--animate-delay) * 8);
}
