@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}
code {
  font-family: "Open Sans", sans-serif;
}

body {
  /* background: transparent
    linear-gradient(180deg, #e2e8fc 0%, #f0f4fd 31%, #f7f9fe 100%) 0% 0%
    no-repeat padding-box; */
  background-color: #f5f8fe;
  opacity: 1;
}

@media (min-width: 900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1700px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1600px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1365px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row2 {
  margin-right: calc(var(--bs-gutter-x) * -0) !important;
  margin-left: calc(var(--bs-gutter-x) * -0) !important;
}

.bg-about {
  background-color: #282d36;
}
.bg-base {
  background-color: #e2e8fc;
}
.w-fullscreen {
  width: -webkit-;
}

/* custom slider */
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #ced8f2;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #3e70fc;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #3e70fc;
  cursor: pointer;
}

/* Basic colors */
.bg-nani {
  background-color: #dce4f8;
}

.bg-blue-primary {
  background-color: #3e70fc;
}
.text-media-CenterLeft {
  text-align: center;
}
@media (min-width: 1024px) {
  .text-media-CenterLeft {
    text-align: left;
  }
}
.text-media-CenterLeftXL {
  text-align: center;
}
@media (min-width: 1280px) {
  .text-media-CenterLeftXL {
    text-align: left;
  }
}
.responsive-mx {
  margin-left: auto;
  margin-right: auto;
}
.rocket-desktop {
  left: 10%;
}

.btn-start {
  background-color: #f7f9ff;
  box-shadow: 0px 3px 6px #6cded47e;
  border: 2px solid #04e0cd;
}

.gradient-wow {
  background: rgb(226, 232, 252);
  background: linear-gradient(
    180deg,
    rgba(226, 232, 252, 1) 0%,
    rgba(240, 244, 253, 1) 42%,
    rgba(247, 249, 254, 1) 100%
  );
}
.shadow-bigger {
  -webkit-box-shadow: 5px 5px 7px -13px rgba(62, 112, 252, 0.03),
    0px 0px 14px 0px rgba(62, 112, 252, 0.3);
  box-shadow: 5px 5px 7px -13px rgba(62, 112, 252, 0.03),
    0px 0px 14px 0px rgba(62, 112, 252, 0.3);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* CUSTOM INPUT SELECT */
/* flecha custom */
.select-selected {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}
.select-selected select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  background: #ffffff;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select-selected select::-ms-expand {
  display: none;
}
.select-selected select:hover,
.select-selected select:focus {
  color: #7b7b7b;
  background: #ffffff;
}
.select-selected select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select-selected_arrow {
  position: absolute;

  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0px 5px;
  border-color: #7b7b7b transparent transparent transparent;
}
.select-selected select:hover ~ .select-selected_arrow,
.select-selected select:focus ~ .select-selected_arrow {
  border-top-color: #7b7b7b;
}
.select-selected select:disabled ~ .selec-selected_arrow {
  border-top-color: #7b7b7b;
}
.mb-1_res {
  margin-bottom: 0.5rem;
}
.mb-2_res {
  margin-bottom: 1rem;
}
.mb-3_res {
  margin-bottom: 1.5rem;
}
.center-absolute {
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -2;
}

/* *************** */
/*  F U E N T E S  */
/* *************** */
@font-face {
  font-family: "poppins-regular";
  src: url("/src/assets/fonts/Poppins-Regular.ttf") format("truetype");
}
.poppins-regular {
  font-family: "poppins-regular";
}

@font-face {
  font-family: "poppins-bold";
  src: url("/src/assets/fonts/Poppins-Bold.ttf") format("truetype");
}
.poppins-bold {
  font-family: "poppins-bold";
}

@font-face {
  font-family: "poppins-semibold";
  src: url("/src/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}
.poppins-semibold {
  font-family: "poppins-semibold";
}

@font-face {
  font-family: "poppins-medium";
  src: url("/src/assets/fonts/Poppins-Medium.ttf") format("truetype");
}
.poppins-medium {
  font-family: "poppins-medium";
}

.icon-feature {
  margin: auto 0.5rem;
}

@media (max-width: 1000px) {
  .hidden-collapse-mobile {
    display: none;
  }
  .play {
    left: 50%;
  }
}

@media (min-width: 1000px) {
  .hidden-collapse-desktop {
    display: none;
  }
}
@media (max-width: 1280px) {
  .hidden-contact-mobile {
    display: none;
  }
}

@media (min-width: 1280px) {
  .hidden-contact-desktop {
    display: none;
  }
}
/* ******************* */
/* A N I M A T I O N S */
/* ******************* */
.heart {
  animation: heart 2s ease 1s infinite normal forwards;
}
@keyframes heart {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/**
 * SCROLLERINIFINITE
 *
 **/
.scroller {
  animation: scrollInfinite 30s linear infinite;
}

@keyframes scrollInfinite {
  0% {
    transform: translatex(0%);
  }
  100% {
    transform: translatex(-100%);
  }
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s ease-in-out both;
  animation: scale-up-center 0.4s ease-in-out both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.swing {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scale-up-top {
  -webkit-animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-10-22 15:52:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}
@keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

.float {
  animation-name: float;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  margin-top: 5px;
}

@keyframes float {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.shake-rocket {
  -webkit-animation-name: shake-rocket;
  animation-name: shake-rocket;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes shake-rocket {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}
@keyframes shake-rocket {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}

.genially-container {
  background-color: rgb(10, 10, 15);
}

.genially-bg {

  width: 100%;
  height: 100vh;
  background-color: rgb(10, 10, 15);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 3.8em;
}

.genially-bg-simple {

  width: 100%;
  height: 100vh;
  background-color: rgb(10, 10, 15);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  /*margin-top: 3.8em;*/
}

.msg {
  width: 100%;
  height: 100vh;
  background-color: rgb(10, 10, 15);
  padding: 10%;
  margin: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  color: aliceblue;
  text-align: center;
}

@media only screen and (orientation:portrait) { 
  .msg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}