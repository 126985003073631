.line {
  width: 100%;
  height: 3px;
  background: transparent
    linear-gradient(
      90deg,
      #00eaca 0%,
      #04e0cd 10%,
      #11c6d8 27%,
      #269de9 48%,
      #3e70fc 69%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
}

.DoitXrLogo {
  width: 8vw;
}
@media (max-width: 700px) {
  .DoitXrLogo {
    width: 10em;
  }
}
@media (max-width: 1200px) {
  .DoitXrLogo {
    width: 10em;
  }
}

.textNavbar {
  text-align: left;
  font: normal normal medium 14px/58px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  margin-right: 1em;
  outline: none;
  padding: 0.4em;
}

.textNavbar a {
  text-decoration: none !important;
  color: #16171b;
}

.buttonNavbar {
  outline: none;
  text-decoration: none;
  background: #3e70fc 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  padding: 0.4em;
}
.p-butonNavbar {
  padding: 0;
}

.buttonNavbar a {
  color: white !important;
  text-decoration: none !important;
  margin: 1em;
}

.signup {
  color: #8694b9 !important;
  opacity: 1;
}

.login {
  color: #3e70fc !important;
  opacity: 1;
}

.toggler {
  color: #3e70fc !important;
}

.button-toggler {
  background-color: white;
  border-radius: 50px;
  padding: 0.4em;
}

@media (min-width: 992px) {
  .hidden {
    display: none !important;
  }
  .p-butonNavbar {
    padding: 0rem 1rem;
  }
}

@media (max-width: 991px) {
  .brand {
    width: 100%;
  }
  .mobile {
    display: contents !important;
  }
}
