.title-F {
  font: 1.2em;
  letter-spacing: 3px;
  color: #3e70fc;
  text-transform: uppercase;
  opacity: 1;
}

.text-F-links {
  text-decoration: none !important;
  letter-spacing: 0px;
  color: #4f535d;
  opacity: 1;
}

.footer-link {
  font-weight: bold;
}

.colorBlue {
  font-size: 1em;
  color: #3e70fc;
}

.social-media {
  color: #757b89;
}

.identidad-1 {
  text-align: right;
  font-size: 0.7em;
  letter-spacing: 0px;
  color: #757b89;
}

.identidad-2 {
  text-align: right;
  font-size: 0.8em;
  letter-spacing: 0px;
  color: #3e70fc;
  text-decoration: none;
}
